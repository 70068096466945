/* eslint-disable consistent-return */
/* eslint-disable no-unreachable */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Fri Jan 22 2021
 *  File : PostAuthMap.jsx
 *******************************************/

import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {useRouter} from 'next/router';

import ActionCreators from '../../store/ActionCreators';
import {verifyTransaction, fetchTreeDetail} from '../../store/trees/ThunkActions';
import { postAuthPaths } from '../../config/constants/NavigationConstants';
import { CustomModal, TreeCounter, CustomLoader, CustomHeader, TreePopUp} from '../../components/commons';
import ClusterCompanyMap from '../../components/forest/clusterComapanyMap';
import {GeolocationUtil, useStateCallback, useWarnIfUnsavedChanges} from '../../utils';
import TransactionTable from '../../components/forest/transactionTable';
import CompanyForest from '../../components/companyForest/CompanyForest';

import styles from './PostAuthCompanyMap.module.css';

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	user: state.customerInfoReducer,
	trees: state.treesReducer,
    companyForest: state.companyForestReducer,
});

const mapDispatchTopProps = (dispatch) => bindActionCreators(ActionCreators, dispatch);

const connector = connect(mapStateToProps, mapDispatchTopProps);

const whiteListedZoomLevels = [3, 4, 5, 6, 12, 16, 20, 21];

const DEFAULT_ZOOM = whiteListedZoomLevels[0];
/**
 * BACKEND_FRONTEND_ZOOM_RATIO = 1.83 is backend_max_zoom divided by frontend_max_zoom
 */
const BACKEND_FRONTEND_ZOOM_RATIO = 1.83;	// 22 divided by 12

const PostAuthMap = ({...props}) => {
	// since we are importing styles from component level file
	const modalAbsolutePositionClass = styles['modal-dialog-design'];
	// router object to get query params
	const router = useRouter();
	// redux state and thunk method from redux store
	const {
		auth, user, trees, 
		fetchCompanyTrees,
		claimTreeRequest, 
		chooseTree, unChooseTree,
		fetchPlantedTreeCount, 
		fetchAllowedTreeCount,
		storeMeetingData,
		clearChoosenTrees,
        companyForest,
	} = props;

	const {
		isFetchingTrees, treeData, 
		choosenTrees, urlApiSource, 
		urlSelectedTreeCount, urlTransactionId, 
		allowedTree, 
		transactions, responseMessage,
		isLoading,
		totalTrees,
	} = trees;
	const {email = null} = auth.credentials;
	const whiteListedZoomLevels = [3, 4, 5, 6, 12, 16, 20, 21];
	const showMarkerOnZoomLevel = 15;
	
	const {allAvailableTreeCounts} = transactions;
	const maxAvailableTreeCount = Math.max( ...allAvailableTreeCounts);
	const minAvailableTreeCount = Math.min(...allAvailableTreeCounts);
	// panacea data to check if the user has verified from diners page
	// before adding a tree again
	const {isVerified} = user.panaceaData;
	
	// get the query parameters from the url
	const urlSelectedTreeCount_F = props.count;
	const urlApiSource_F = props.transaction_system;
	const urlTransactionId_F = props.transaction_id;
	const urlEmailId_F = props.email;
	const urlRestaurantName_F = props.restaurant_name;
	const urlRestaurantId_F = props.restaurant_id;

	const urlSwitcherState = props.switcherState;
	
	let stateOfSwitcherState = urlSwitcherState || 'LEFT';

	if(Object.keys(choosenTrees).length !== 0 || urlSwitcherState === 'RIGHT' || urlApiSource_F || urlApiSource){
		stateOfSwitcherState = 'RIGHT';
	}

	const [state, setState] = useStateCallback({
		/**
		 * The first map api call is different from later stages
		 * the first time we call dynamic map fetch api and later on normal
		 * fetch tree apis.
		 */
		isFirstMapApiCall: true,
		isFetching: true,
		showLoader: true,
		claimTreeRequestApiCalled: null,
		pos: {
			lat: -4.5111298721412965,
			lng: 35.52958761763869,
		},
		error: false,
		selectedSwitcherState: stateOfSwitcherState,
		/**
		 * questionnaire object holds data for modal when asking questions to user
		 * on adding a new tree to forest
		 */
		questionnaire: {
			transactionSystem: urlApiSource,
			transactionId: urlTransactionId,
			// allowedTreesInTransaction is the number of trees user can select using above transaction			
			allowedTreesInTransaction: urlSelectedTreeCount,
			meetingPagination: 1
		}
	});

	const [modal, setModal] = useStateCallback({
		modalTitle: (<h4 className='tree-popup-title'>More about this GiftTree</h4>),
		modalBody: [],		
		showModal: false,
		isTreeTable: false
	});

	const [isModalOnLeft, setIsModalOnLeft] = React.useState(false);

	const totalCompanyTrees= totalTrees? totalTrees :companyForest.mpUserData.total_trees;

	const mapRef = React.useRef();

	/**
	 * To prevent site from leaving without completion of
	 * asynchronous function we use following utils
	 */
	useWarnIfUnsavedChanges(state.showLoader);

	// initialize the page
	React.useEffect(() => {
		initialize();

		return ()=>{
			setState({
				...state,
				claimTreeRequestApiCalled: null
			});
			setModal({
				...modal,
				showModal: false,
			})
		}
	}, []);
	
	// when to activate notification modal or call claimTreeRequest Api
	React.useEffect(()=>{
		if(responseMessage && state.claimTreeRequestApiCalled===false){
			createApiResponseMessage();	
		}
		if(state.claimTreeRequestApiCalled && Object.keys(choosenTrees).length > 0) {
			onClaimTreeRequest();
		}
	}, [state.claimTreeRequestApiCalled]);


	const createApiResponseMessage = () => {
		const modalBody = [];
		modalBody.push(
			<div>
				<span>{responseMessage}</span>
			</div>
		);
		const modalTitle = 'Notification';
		setModal({
			...modal, 
			modalBody, 
			modalTitle,
			showModal: true
		});

		setIsModalOnLeft(false);
	}

	const initialize = () => {

		// store meeting Details to redux store for further processing
		if(urlApiSource_F && urlSelectedTreeCount_F && urlTransactionId_F){
			/**
			 * One more scenario we need to take into consideration
			 * i.e when user has come from CTA url then the email is provided along with it
			 * we need to verify if the email on that CTA link is same as the user 
			 * already loggedIn
			 */
			if(email === urlEmailId_F) {
				// first verify whether the transaction is valid or not
				// before storing the details in localStorage
				verifyTransaction({
					transactionId: urlTransactionId_F, 
					treeCount: urlSelectedTreeCount_F, 
					email : urlEmailId_F, 
					transaction_system: urlApiSource_F
				})
				.then((res)=>{
					if(res.data.status === 200){
						storeMeetingData({
							urlApiSource: urlApiSource_F, 
							urlSelectedTreeCount: urlSelectedTreeCount_F, 
							urlTransactionId: urlTransactionId_F,
							urlRestaurantName: urlRestaurantName_F,
							urlRestaurantId: urlRestaurantId_F
						}).then(()=>{
							setState({
								...state,
								showLoader: false,
								isFetching: false,
								questionnaire: {
									...state.questionnaire,
									transactionSystem: urlApiSource_F,
									allowedTreesInTransaction: urlSelectedTreeCount_F,
									transactionId: urlTransactionId_F
								}
							})
						})
					}else {
						// if there's error in the transaction notify the user
						const modalBody = (
							<div>
								<span>This is an invalid transaction. You cannot use this to add trees in your forest!</span>
							</div>
						);

						setModal({
							...modal,
							modalTitle: 'Oops!',
							modalBody,
							showModal: true
						});

						setIsModalOnLeft(false);

						setState({
							...state,
							isFetching: false,
							showLoader: false
						});						
					}
				})
				.catch((err)=>{
					console.log(err);
					setState({
						...state,
						showLoader: false
					})
				})
			} 
			else {
				// if email provided in CTA is different from that in user loggedin
				// mail then appropriate notification shown
				const modalBody = (
					<div>
						<span>
							This transaction can only be used by {urlEmailId_F}. Please Sign Out and click on CTA button once again.
						</span>
					</div>
				);
				setModal({
					...modal,
					modalTitle: 'Oops!',
					modalBody,
					showModal: true,
				});

				setIsModalOnLeft(false);

				setState({
					...state,
					isFetching: false,
					showLoader: false
				});
								
			}
		}
		// if the urlApiSource is restaurant then we need to follow different
		// path
		else if(urlApiSource_F === 'restaurant'){
			storeMeetingData({
				urlApiSource: urlApiSource_F,
				urlRestaurantName: urlRestaurantName_F,
				urlRestaurantId: urlRestaurantId_F,
				urlSelectedTreeCount: 1, 
				urlTransactionId: -1
			}).then(()=>{
				setState({
					...state,
					isFetching: false,
					showLoader: false,
					questionnaire: {
						...state.questionnaire,
						transactionSystem: urlApiSource_F,
						allowedTreesInTransaction: 1,
						transactionId: -1
					}
				})
			})			
		}
		// we have one more condition where user had preselected more than 1 tree using some transaction and 
		// reloaded the page
		// this will remove the transaction information but choosen trees will be present.
		// this can make issue
		else {
			console.warn('This is risky business, if transaction not present all choosen trees will disappear');
			// check length of choosen trees
			if(Object.keys(choosenTrees).length > 1 && state.questionnaire.transactionId === null) {
				// clear choosen trees
				clearChoosenTrees();
			}
			setState({
				...state,
				// showLoader: false,
				isFetching: false
			})
		}
	};


	const hideModal = () => {
		// reset all modal related data
		setModal({
			...modal,
			showModal: false,
		});
	};


	const getMapCoordinates = () => {
		// using the map object we are able to access getBounds method
		// but the catch being it wont give us NW and SE points
		// so we use basic replacement calculation 
		// i.e: if NW : (9, 10) and SE : (11, 12) 
		// then    NE : (9, 12) and SW : (11, 10)
		// this is not 100% accurate in cases where there is
		// orientation change on map		
		const map = mapRef.current.map_;
		if(map!==null){
			const {zoom} = map;
			const ne = map.getBounds().getNorthEast();
			const sw = map.getBounds().getSouthWest();
			const neLat = ne.lat();
			const neLng = ne.lng();
			const seLat = sw.lat();
			const seLng = sw.lng();
			const nw = {
				lat: neLat,
				lng: seLng
			}
			const se = {
				lat: seLat,
				lng: neLng
			}
			return {
				nw,
				se,
				zoom: zoom / 1.83
			}
		}
		return null;
	}

	const createNotAllowedClaimModal = (source = 'restaurant') => {
		const onBtnClick = () => {
			setModal({
				...modal,
				showModal: false
			})
			router.push(postAuthPaths.DINERS);
		}

		const modalBody = [];
		const modalTitle = 'Oops!!';
		modalBody.push(
			<div style={ {display: 'flex', flexDirection: 'column'} }>
				{/* <span>Oops!!</span> */}
				<span style={ {marginBottom: 10} }>
					No claimable trees for you. Please verify your bill from GiftTrees Restaurant to add more trees.
				</span>
				<div style={ {display: 'flex', flexDirection: 'row', justifyContent: 'center'} }>
					<button type='button' className={styles.addTreeToForestBtn} onClick={ onBtnClick }>
						Verify Bill
					</button>
				</div>
			</div>
		);
		setModal({
			...modal,
			modalBody,
			modalTitle,
			showModal: true,
		});

		setIsModalOnLeft(false);
	}

	const onMapReady = (map, maps) => {
		/**
		 * If the user has already added some trees then
		 * zoom on those trees
		 */
		if (Object.keys(choosenTrees).length > 0 && stateOfSwitcherState === 'RIGHT') {
			// select first marker from the Object list
			const marker = Object.entries(choosenTrees)[0][1];
			map.setZoom(18);
			panToCluster(marker);
			try {
				/**
				 * This is the logic for the modal to pop up containing information about 
				 * selected marker
				 * NOTE: The modal containing the marker info should only popup
				 * if the user has claimable trees or when the user source is restaurant
				 */
				if(allowedTree > 0 || urlApiSource === 'restaurant'){
					// for source is restaurant the flow is kind of complex
					onClickMarker(false, marker);
				}else{
					/**
					 * This portion of code only to be executed when we are completely sure
					 * the api call has been made and all claimable Tree counts been fetched
					 * and user has preselected tree which he is going to claim
					 */

					createNotAllowedClaimModal();
				}
			} catch (error) {
				console.log(marker);
				console.log(error);
			}
		}else{
			onFetchTrees(null, true);
		}

	}

	const createPostBodyDataForFetch = () => {
		let getMapData = getMapCoordinates();
		let postBodyData = undefined;

        let email = '', panacea_id = null; let forestType=null;
        if(companyForest.isValid) {
			email = companyForest.mpUserData.email;
			panacea_id = companyForest.mpUserData.id;
			forestType= companyForest.mpUserData.forestType;
		}

		if(getMapData!=null){
			let {nw, se, zoom} = getMapData;
			postBodyData = {
				precision: zoom,
				top_left_point: {
					lat: Math.min(Math.max(nw.lat, -90), 90),
					lon: Math.min(Math.max(nw.lng, -180), 180),
				},
				bottom_right_point: {
					lat: Math.min(Math.max(se.lat, -90), 90),
					lon: Math.min(Math.max(se.lng, -180), 180),
				},
				email: email,
				panacea_id: panacea_id,
				forestType :forestType
			};
		}

		return postBodyData;
	}

	const onFetchTrees = (stateVal = null, switcherStateChanged = false) => {
		const {isFirstMapApiCall} = state;
		let postBodyData = createPostBodyDataForFetch();
		if(postBodyData!=undefined){
			// if there already is a api call happening
			// dont do unless it is over
			if(!isFetchingTrees){
				/**
				 * We have two types of fetch apis which can be called.
				 * One is when first api call happens which calls dynmaic fetch trees api
				 * and rest on is normal api calls
				 */
				//let changeMapPan=false;
				if(isFirstMapApiCall || switcherStateChanged){

					// if the onFetchTrees api was called just because panToCluster had been called
					// for the isFirstMapApiCall logic then dont do it
					// 3.28 is 6/1.83

					// if its already firstMapApiCall then we need to make sure that it's really first and not
					// repeated
					// if switcherState is changed then we need not stop the api call

					if(
						!switcherStateChanged && 
						Math.round(postBodyData.precision) >= Math.round(DEFAULT_ZOOM/BACKEND_FRONTEND_ZOOM_RATIO)
					) {
						console.warn("Dynamic trees fetch already happened. Stopping the propagation");
						setState({
							...state,
							isFirstMapApiCall: false
						})
						return;
					}

					// if modal is shown dont show loader
					if(!modal.showModal) {
						setState({
							...state,
							showLoader: true
						});
					}
				}

				/**
				 * NOTE:In develop branch fetchDynamicTrees will be replaced by fetch trees
				 */

				const thunkActionToBeCalled = fetchCompanyTrees;

				thunkActionToBeCalled(postBodyData)
				.then((res)=>{

					setState({
						...state,
						// isFirstMapApiCall: false,
						showLoader: false,
						selectedSwitcherState: stateVal || state.selectedSwitcherState
					},()=>{
			
					});
				}).catch((e)=>{
					setState({
						...state,
						showLoader: false
					});					
				});
			}
		}
	};


	const panToCluster = (marker, zoomIn = 0) => {
		if (marker.lat === undefined && marker.lng === undefined) {
			return;
		}
		// if its cluster then the map should pan to that coordinates
		const bounds = new window.google.maps.LatLngBounds();
		bounds.extend({
			lat: Number(marker.lat),
			lng: Number(marker.lng),
		});
		/**
		 * check the zoom level of map and accordingly select
		 * which zoom level to be set
		 */
		const sizeOfWhiteListedLevels = whiteListedZoomLevels.length;
		const currentZoomLevel = mapRef.current.map_.zoom;

		const closest = whiteListedZoomLevels.reduce((prev, curr) =>
			Math.abs(curr - currentZoomLevel) < Math.abs(prev - currentZoomLevel) ? curr : prev,
		);

		const closestIndex = whiteListedZoomLevels.indexOf(closest);
		// check if the closestIndex is not last
		if (typeof whiteListedZoomLevels[closestIndex + 1]!=='undefined' && closestIndex < sizeOfWhiteListedLevels) {
			// there is one more zoom level we can zoom into
			try {
				mapRef.current.map_.fitBounds(bounds);
				if(zoomIn === 0) mapRef.current.map_.setZoom(whiteListedZoomLevels[closestIndex + 1]);
				else mapRef.current.map_.setZoom(zoomIn);
			} catch (error) {
				console.log(error);
			}
		}else {
			/**
			 * Some error occurred like when user clicks on marker so fast we arent able to 
			 * propagate that to maps mapRef.current.map_.setZoom(?) seems to error.
			 * We will set a step back and set current zoom level to whiteListedZoomLevels[closestIndex]
			 */
			 mapRef.current.map_.setZoom(whiteListedZoomLevels[closestIndex - 1]);
			console.warn('Errored out!, current zoom level : ', currentZoomLevel, ' closest index : ', closestIndex)

		}	
	}

	const onClaimTreeRequest = () => {

		// console.log('onClaimTreeRequest');

		if(urlApiSource === 'restaurant' && !isVerified){
			// check if 1 tree has already been added and
			// also the bill detail has been verified
			setModal({
				...modal,
				showModal: false
			});
			router.push(postAuthPaths.DINERS);
			return;
		}
		
		if(allowedTree <=0) {
			// if by somehow the code flow reaches to claiming a tree
			// have a check if the user can actually claim a tree
			// if not, notify the user

			// createNotAllowedClaimModal();
			return;
		}

		// hide the modal		
		setModal({
			...modal,
			showModal: false,
		})
		// show loader		
		setState({
			...state,
			showLoader: true,
		});

		const treeTokens = Object.keys(choosenTrees);
		// call thunk action
		const {transactionId, transactionSystem} = state.questionnaire;
		// console.log(transactionId, transactionSystem, treeTokens);
		// eventhough we refer panacea => restaurant

		claimTreeRequest(
			transactionId, 
			transactionSystem, 
			treeTokens, 
			email
		)
		.then(()=>{
			// dispatch event to refresh user wallet and tree count
			// since the previous process takes time to resolve
			// lets wait for some time
			setTimeout(() => {
				fetchPlantedTreeCount(email);
				fetchAllowedTreeCount(email);				
				setState({
					...state,
					showLoader: false,
					claimTreeRequestApiCalled: false,
					questionnaire: {
						...state.questionnaire,
						allowedTreesInTransaction: null,
						transactionId: null
					}
				});
			}, 5000);
		})
		.catch(()=>{
			setState({
				...state,
				showLoader: false,
			});
		})
	}	

	// This function will help select transactional details when
	// user clicks on addMoreTrees button
	const createTransactionSelectionModal = () => {
		const modalTitle = 'Trees Available to choose:';
		const isTreeTable = true;
		// find on which stage is the question on
		/**
		 * When adding a tree to forest we can follow three separate paths
		 * 1. Ask if he wants to add more trees if yes continue with selection else step 2
		 * 2. Ask him if he wishes to add tree to any transactions or randomize, if transactions step 3 else 4
		 * 3. Make him select type of transaction system
		 * 4. continue without transaction
		 */

		const onSelectTransaction = (transactionSystem, transactionId, treeCount) => {
			console.log(transactionId,'stored');
			setState({
				...state,
				showLoader: false,
				questionnaire: {
					...state.questionnaire,
					transactionSystem,
					transactionId,
					allowedTreesInTransaction: treeCount
				}
			}, ()=>{
				// hide the modal
				try {
					setModal({
						...modal,
						showModal: false,
					});

					setIsModalOnLeft(false);
				} catch (error) {
					console.log(error);
				}
			});
		}

		/**
		 * Rendering Modal happens from below this line
		 */

		/**
		 * set state for modal to appear
		 */
		 setModal({
			...modal,
			modalBody: <TransactionTable transactions={ transactions } onSelectTransaction={ onSelectTransaction }/>,
			modalTitle,
			showModal: true,
			isTreeTable,
		});

		setIsModalOnLeft(false);
	}

	const addTreeToForest = (
		choosenTree = null, 
		isSingleClaim,
		isAddingTokenCompleted = false
	) => {
		/**
		 * We have to be careful on what exactly user wants to do
		 * add trees to local storage before claiming
		 * or directly claim the tree
		 * 
		 * 1. Adding trees to local storage will happen until and unless the user
		 *    has choosen number of trees === allowedTreeCount for the transaction he has chosen
		 *    - after that reaches he will be claiming all the trees he had added
		 * 2. If all the transactions are allowing tree count = 1 then he can directly claim tree
		 * 
		 */
		if(allowedTree <= 0 && urlApiSource !== 'restaurant') {
			// notify user if allowedTree is 0
			// but this notification wont happen if the 
			// user comes from source = restaurant
			chooseTree({choosenTree}) // reduxThunk function
			.then(()=>{
				createNotAllowedClaimModal();
				return;
			})
		}

		 if(isAddingTokenCompleted){
			// this is activated when user has added all the trees
			// and user clicks on counter for claiming tree 
			setState({
				...state,
				claimTreeRequestApiCalled: true
			})
			 return;
		}

		if(choosenTree && isSingleClaim){
			// when no transaction was selected
			// make an ajax call to add this tree
			if(state.questionnaire.transactionId === null){
				// when the tree claim is without transaction 
				// that is single tree claim
				chooseTree({choosenTree}) // reduxThunk function
				.then(()=>{
					setState({
						...state,
						claimTreeRequestApiCalled: true
					})
				})
			}else {
				// when user has selected transaction and can do multiple
				// tree selection
				// eslint-disable-next-line no-lonely-if
				if(urlApiSource === 'restaurant'){
					// check if 1 tree has already been added and
					// also the bill detail has been verified
					if(isVerified===true){
						// if bill verification was successful
						chooseTree({choosenTree}) // reduxThunk function
					}else if(Object.keys(choosenTrees).length === 0){
						// if there has no trees been added to the list
						// first tree can be added and as per design he will be
						// redirected to diners page
						chooseTree({choosenTree}) // reduxThunk function
						.then(()=>{
							// after adding first tree, navigate to bill verification
							router.push(postAuthPaths.DINERS);
						});
					}else{
						// if bill verification is not done
						// and a tree has already been added
						// restrict user to add any more trees, unless verified
						router.push(postAuthPaths.DINERS);						
					}
				}else {
					chooseTree({choosenTree}) // reduxThunk function
				}
			}

			// hide the modal
			try {
				setModal({
					...modal,
					showModal: false
				})
			} catch (error) {
				console.log('Jquery not loaded');
			}

		}
		else {
			// this is when user wants to choose a transaction
			// first we have to switch off already opened modal
			chooseTree({choosenTree})
			.then(()=>{
				createTransactionSelectionModal();
				return;
			})
		}
	}

	const onUnChooseTree = (token) => {
		setModal({
			...modal,
			modalBody: [],
			showModal: false,
		}, ()=>{
			unChooseTree({unChoosenTree: token});
			/**
			 * Bug fix: While redirecting from diners page,
			 * after unchoosing a tree, trees were not shown in the map.
			 * Calling the fetch API to get the trees on the map again
			 */
			onFetchTrees('RIGHT', true);
		});
	}

	// when clicked on tree all a tree or preselected tree this modal will popup
	// conatining tree realted information
	const createModalBodyFromMarkerObject = async(marker) => {
		const {image_url, region, tree_captured_at, token, species, farmer_first_name,farmer_last_name ,farmer_image_url, lat, lng} = marker;
		// check if token is saved
		const isSelected = token in choosenTrees;
		const date = new Date(tree_captured_at);
		const addData = {};
		addData[token] = {
			...marker
		};

		// The all the trees required has already been added and no more
		// trees available then Maximum Limit Reached
		let hasReachedTreeSelectLimit;
		if(urlApiSource){
			// if user was redirected here from an url
			hasReachedTreeSelectLimit = Object.keys(choosenTrees).length === Number(urlSelectedTreeCount);
		}else if(state.questionnaire.transactionId) {
			// if user has selected any transactions then
			hasReachedTreeSelectLimit = Object.keys(choosenTrees).length === state.questionnaire.allowedTreesInTransaction;				
		}else{
			// if user wants the default 1 tree count selection
			hasReachedTreeSelectLimit = Object.keys(choosenTrees).length === Number(urlSelectedTreeCount);
		}

		const buttonContent = [];

		if(state.selectedSwitcherState === 'RIGHT'){
			if(!isSelected && !hasReachedTreeSelectLimit){
				// it's better to show addMoreTrees button only if the user has already some transactions
				// where he can select more than 1 tree and has not already selected that transaction

				const showAddMoreTreesButton = maxAvailableTreeCount > 1 && state.questionnaire.transactionId === null;

				/**
				 * Before any tree is selected we have Add more trees and add tree to forest button
				 * visible. Dependent on the following scenario we choose whether to show add tree to forest button or not
				 * 
				 * 1. If user has no transaction at all also show => to call diners verification
				 * 2. If user has transaction with tree count === 1
				 * 3. If user has preselected a tree or not and selected a transaction 
				 *    and number of choosen trees is less than allowed trees in that transaction
				 */

				const showAddSingleTreeButton = (
					allAvailableTreeCounts.length === 0 ||					
					minAvailableTreeCount === 1 || 
					(
						Object.keys(choosenTrees).length >=0 && 
						state.questionnaire.transactionId !== null &&
						Object.keys(choosenTrees).length < parseInt(state.questionnaire.allowedTreesInTransaction)
					)
				);

				buttonContent.push(
					<div 
						key={ 1 }
						className={styles.buttonContainer}
						style={ {justifyContent: 'center'} }
					>
					{
						showAddSingleTreeButton && (
							<button 
								type='button' 
								className={styles.addTreeToForestBtn} 
								onClick={ () => addTreeToForest(addData, true) }
							>
								Add Tree To Forest
							</button>
						)
					}
					{
						showAddMoreTreesButton && (
							<button 
								type='button' 
								className={styles.addTreeToForestBtn} 
								onClick={ () => addTreeToForest(addData, false) }
							>
								Add More Trees To Forest
							</button>								
						)
					}
				</div>
				);
			}else if(isSelected){
				let addTreeToForestButton;
				/**
				 * When user preselects n number of trees and comes to this page without CTA or has not selected
				 * any transaction previously then we have to make sure that this user actually has any transaction
				 * that actually supports the number of preselected trees he has came up with
				 * Following conditions need to be checked
				 * 1. If he has not selected any transaction previously
				 * 2. If he has transaction where his preselection matches
				 */
				const hasNoTransactionWithNumberOfChoosenTrees = (
					state.questionnaire.transactionId === null &&
					allAvailableTreeCounts.length > 0 &&
					!allAvailableTreeCounts.includes(Object.keys(choosenTrees).length)
				);
				// if the marker is selected, an option to unselect should be present
				// NOTE:if the uriApiSource is RESTAURANT and chooseTrees.length === 1
				// then add Tree to forest button be visible
				if(urlApiSource === 'restaurant' && Object.keys(choosenTrees).length === 1){
					addTreeToForestButton = (
						<button 
							type='button' 
							className={styles.addTreeToForestBtn} 
							onClick={ () => addTreeToForest(null, false, true) }
						>
							Add Tree To Forest
						</button>
					);
				}
				// if the user has no transaction with tree count === number of choosen trees
				// then he should be given option to select a transaction for that 
				else if (hasNoTransactionWithNumberOfChoosenTrees){
					addTreeToForestButton = (
						<button 
							type="button" 
							className={styles.addTreeToForestBtn} 
							onClick={ () => addTreeToForest(addData, false) }
						>
							Add More Trees To Forest
						</button>
					);
				}				
				// if the user has selected a transaction and number of choosen trees match the transaction
				// tree count then add tree to forest button MUST be shown
				else if(
					state.questionnaire.transactionId !== null && 
					Object.keys(choosenTrees).length === Number(state.questionnaire.allowedTreesInTransaction)
				) {
					addTreeToForestButton = (
						<button 
							type='button' 
							className={styles.addTreeToForestBtn} 
							onClick={ () => addTreeToForest(null, false, true) }
							style={ {marginRight: 10} }
						>
							Add Tree To Forest
						</button>
					);

				}
				// if the user has preselected only 1 tree and has not selected any transactions
				// or if the transaction tree count is 1 then also add tree to forest button 
				// must be shown
				else if(
					state.questionnaire.transactionId === null && 
					Object.keys(choosenTrees).length === 1 && Number(urlSelectedTreeCount) === 1
				) {
					addTreeToForestButton = (
						<button 
							type='button' 
							className={styles.addTreeToForestBtn} 
							onClick={ () => addTreeToForest(null, false, true) }
							style={ {marginRight: 10} }
						>
							Add Tree To Forest
						</button>
					);
				}

				buttonContent.push(
					<div 
						key={ 1 }
						className={styles.buttonContainer}
						style={ {justifyContent: 'center'} }
					>
						{ addTreeToForestButton }

						<button 
							type='button' 
							className={styles.addTreeToForestBtn} 
							onClick={ () => onUnChooseTree(token) }
							style={ {backgroundColor: '#4a4a4a'} }
						>
							Deselect Tree
						</button>
					</div>
				);
			}
		}


		setModal({
			...modal,
			modalTitle: (<h4 className='tree-popup-title'>More about this GiftTree</h4>),
			modalBody: (
				<div className={styles.treeDetailBody} key={1}>
					<TreePopUp
						image_url={image_url}
						species={species}
						farmer_first_name={farmer_first_name}
						farmer_last_name={farmer_last_name}
						farmer_image_url={farmer_image_url}
						lat={lat}
						lng={lng}
					></TreePopUp>
					{buttonContent}
				</div>
			),
			showModal: true,
		});

		setIsModalOnLeft(true);
	};

	const onClickMarker = (isCluster, marker) => {
		try {
			if(isFetchingTrees){
				return;
			}
			if (!isCluster) {
				/**
				 * createModalBodyFromMarkerObject creates state for new marker data
				 * to be displayed in modal.
				 * To actually display the modal we have to toggle the modal class
				 */
				const {token} = marker;

				fetchTreeDetail(token)
				.then(res => createModalBodyFromMarkerObject(res));

				return;
			}
			
			// pan map to given marker location			
			panToCluster(marker);			
		} catch (error) {
			console.log(error);
		}
	};





	return (
    <div style={ {height: '100%', width: '100%', position: 'absolute', top: 0} } className="forest-map">

		
		<CustomLoader 
			showLoader = { state.showLoader }
		/>
        {!isLoading && !state.isFetching && (
			<>
			<CompanyForest
			companyForest= { companyForest }
			totalCompanyTrees ={totalCompanyTrees}
			>

			</CompanyForest>
			
				<ClusterCompanyMap
					{ ...props }
					mapRef={ mapRef }
					centerCoordinate={ state.pos }
					onMapReady = { onMapReady }
					markerCoordinates={ treeData }
					defaultZoom={DEFAULT_ZOOM}
					onClickMarker={onClickMarker}
					onChange={onFetchTrees}
					showMarkerOnZoomLevel={showMarkerOnZoomLevel}
					isFetchingTrees={isFetchingTrees}
					choosenTrees={ choosenTrees }
				/>
			</>
		)
		}
        <CustomModal
				show={ modal.showModal }
				modalTitle={ modal.modalTitle }
				showModalFooter={ false }
				className={isModalOnLeft ? modalAbsolutePositionClass : `cmn-modal ${modal.isTreeTable ? 'choose-trees-modal ' : ''}`}
				onClose={ hideModal }
			>
            {modal.modalBody}
        </CustomModal>		
    </div>
	);
};

PostAuthMap.defaultProps = {
	auth: {},
	user: {},
	trees: {},
	fetchTrees: null,
	fetchCompanyTrees: null,
	claimTreeRequest: null,
	chooseTree: null,
	unChooseTree: null,
	storeMeetingData: null,
	fetchPlantedTreeCount: null,
	fetchAllowedTreeCount: null,
	location: {},
    companyForest: {},
};

PostAuthMap.propTypes = {
	auth: PropTypes.instanceOf(Object),
	user: PropTypes.instanceOf(Object),
	trees: PropTypes.instanceOf(Object),
	fetchTrees: PropTypes.func,
	fetchCompanyTrees: PropTypes.func,
	claimTreeRequest: PropTypes.func,
	chooseTree: PropTypes.func,
	unChooseTree: PropTypes.func,
	storeMeetingData: PropTypes.func,
	fetchPlantedTreeCount: PropTypes.func,
	fetchAllowedTreeCount: PropTypes.func,	
	location: PropTypes.instanceOf(Object),
    companyForest: PropTypes.instanceOf(Object),
};

export default connector(PostAuthMap);
