/******************************************
 *  Author : Nikhil Unni
 *  Created On : Wed Dec 29 2021
 *  File : CompanyForest.jsx
 *******************************************/
import React, { useState, useEffect } from 'react';
import './CompanyForest.module.css';
import PropTypes from 'prop-types';

const CompanyForest = ({ companyForest,totalCompanyTrees, ...props }) => {
  const [companyName, setCompanyName] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [totalTrees, setTotalTrees] = useState(0);
  const [forestDescription, setForestDescription] = useState(`<p>Welcome to our GiftTree Forest.</p>
  <p>As a company that cares about sustainability, we take our environmental and social impact very seriously.</p>
  <p>Our Company Forest shows all of the GiftTrees planted as a result of our partnership with GiftTrees.</p>`);
    useEffect(() => {
      console.log("companyForest", companyForest)
      if(companyForest && companyForest.mpUserData && companyForest.mpUserData.companyName)
        setCompanyName(companyForest.mpUserData.companyName);
      else if(companyForest && companyForest.mpUserData && companyForest.mpUserData.company)
        setCompanyName(companyForest.mpUserData.company);
      if(companyForest && companyForest.mpUserData && companyForest.mpUserData.company_logo_enabled && companyForest.mpUserData.company_logo)
        setCompanyLogo(companyForest.mpUserData.company_logo);
      if (companyForest && companyForest.mpUserData && companyForest.mpUserData.total_trees)
        setTotalTrees(totalCompanyTrees);
      // update forestDescription
      if (companyForest && companyForest.mpUserData && companyForest.mpUserData.map_details)
        setForestDescription(companyForest.mpUserData.map_details);
      else if (companyForest && companyForest.mpUserData && companyForest.mpUserData.forest_text)
        setForestDescription(companyForest.mpUserData.forest_text);

    }, [totalCompanyTrees,companyForest])
  return (
    <div className="popup-wrap">

      <div className="popup-head">
        {companyLogo &&
        <img className="company-logo"
          src={companyLogo} />
          }
          { companyLogo === '' && <h2>{companyName}'s <br />Forest</h2> }
      </div>
      <div className="accordion-wrap" id="accordionExample">
        <div className="card">
          <div className="card-header" id="headingOne">
            <button className="btn btn-link btn-block" type="button" data-toggle="collapse"
              data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              <i className='fas fa-angle-down'></i>
            </button>
          </div>

          <div id="collapseOne" className="in collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div className="card-body comapny-forest-popup-body">
            {companyLogo &&<h4 >{companyName}</h4> }
            <div dangerouslySetInnerHTML={{ __html: forestDescription }}></div>
            </div>
          </div>
        </div>
      </div>
      <div className="popup-bottom">
        <h3>{totalTrees.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Gift<span>Trees</span></h3>
      </div>
    </div>
  )
}

CompanyForest.propTypes = {
  companyForest: PropTypes.instanceOf(Object)
}

CompanyForest.defaultProps = {
  companyForest: {}
}

export default CompanyForest;