/* eslint-disable no-underscore-dangle */
/******************************************
 *  Author : Suraj Sharma
 *  Created On : Tue Mar 09 2021
 *  File : ClusterCompanyMap.jsx
 *******************************************/
 import React from 'react';
 import supercluster from 'points-cluster';
 import PropTypes from 'prop-types';

 import { useStateCallback } from '../../../utils';
 import {
    CustomMap, 
    CustomMarker, 
} from '../../commons';

const MAP_OPTIONS = {
    minZoom: 2,
    maxZoom: 18,
    radius: 60    
};

export default function ClusterCompanyMap({
    mapRef,
    centerCoordinate,
    onMapReady,
    onChange,
    markerCoordinates,
    onClickMarker,
    showMarkerOnZoomLevel,
    defaultZoom,
    isFetchingTrees,
    choosenTrees
}) {

    const [clusters, updateClusters] = React.useState([]);
    const [mapProps, updateMapProps] = React.useState({});

    const getClusters = (_mapProps) => {
        // console.log("getClusters", _mapProps)
        const clusters = supercluster(markerCoordinates, MAP_OPTIONS);
        return clusters(_mapProps);
    };

    const createClusters = (_mapProps) => {
        // only create clusters for markers
        // for zoomlevel < showMarkerOnZoomLevel its already
        // cluster from back end
        // if(_mapProps.zoom < showMarkerOnZoomLevel) return markerCoordinates;
        // if there are less than some items in markerCoordinates then also dont form group
        /**
         * Code for that
         */

        // else form cluster in front end
        return _mapProps.bounds 
            ? 
            getClusters(_mapProps).map(({ wx, wy, numPoints, points }) => ({
                lat: parseFloat(wy),
                lng: parseFloat(wx),
                numPoints,
                id: `${numPoints}_${points[0].id}`,
                points
            })) 
            : 
            [];
    };

    const handleMapChange = (_mapProps) => {
        // check if there are any data in the clusters
        // also the zoom level is when marker can be seen
        // else make api call to fetch data
        const clusters = createClusters(_mapProps);
        let shouldUpdate = false;
        // if there are no data in clusters then make an api call
        if(clusters.length === 0 || _mapProps.zoom <= showMarkerOnZoomLevel ){
            // console.log('check')
            shouldUpdate = true;
        }

        // some times when user is zooming in and out of page so fast
        // that the clusters get formed for wrong coordinates
        // we need to check that by checking zoom level and existing data
        if(_mapProps.zoom >= showMarkerOnZoomLevel){
            try {
                if(typeof clusters[0]?.points[0]?.count !== 'undefined') shouldUpdate = true;
            } catch (error) {
                console.warn(error);
            }
        }
        // if we need to make fresh api calls
        if(shouldUpdate){
            onChange();
        }

        updateMapProps(_mapProps);        
    }

    React.useEffect(()=>{
        if(
            !isFetchingTrees && 
            Object.keys(mapProps).length > 0
        ){
            const clusters = createClusters(mapProps);
            updateClusters(clusters);
        }
    },[isFetchingTrees, mapProps])

    const renderMarkers = () => clusters.map((item, index) => {

            const key = `${index}key`;
            // sometimes when user is zooming in and out of pages so fast
            // that we will mix the calculation and rendering 
            // that will cause issues where cluster and markers are to be displayed
            // to fix this issue we check for following 3 conditions

            // check if marker occupies cluster space
            if(item.token) return null;
            // check if cluster occupies marker space
            // if(item.numPoints === 1 && item?.points[0]?.count) return null;
            // check if item is invalid
            if(item.numPoints === undefined && item.count === undefined && item.token === undefined) return null;

            const isIndividualMarker = item?.numPoints === 1 && (item?.points && item?.points[0]?.token) || item?.token;

            if (isIndividualMarker) {
                // this can be a individual marker
                // if the marker has already been added
				// the icon should change
                const element = item?.points[0] || item;
				let isSelected = false;
				try {
					isSelected = element.token in choosenTrees;
				} catch (error) {
					console.log(error);
				}
                return (
                    <CustomMarker 
                        key={ key } 
                        lat={ item.lat } 
                        lng={ item.lng }
                        isSelected={ isSelected }
                        onClick = { ()=>onClickMarker(false, element) }
				    />
                );
            }
            // this is for cluster
            // console.log(item, mapProps.zoom);
            let clusterCount = item.numPoints || item.count;

                if(item.numPoints && item.points[0].count) {
                    clusterCount=item.points.reduce((a, b) => (a + b.count), 0)
                }

           
            return (
                <CustomMarker 
					key={ key } 
					lat={ item.lat } 
					lng={ item.lng } 
                    cursor= {isFetchingTrees? 'wait': 'pointer'}
					isCluster 
					clusterCount={ clusterCount}
					onClick = { ()=>onClickMarker(true, item) }
				/>
            );
          })
    
    /**
     * Render method of component
     */
    return(
        <CustomMap
            mapRef={ mapRef }
            centerCoordinate={ centerCoordinate }
            onMapReady = { onMapReady }
            onChange={ handleMapChange }
            defaultZoom={ defaultZoom }
        >
            {renderMarkers()}
        </CustomMap>
    );
}

ClusterCompanyMap.propTypes = {
    mapRef: PropTypes.instanceOf(Object),
    centerCoordinate: PropTypes.instanceOf(Object),
    onMapReady: PropTypes.func,
    onChange: PropTypes.func,
    markerCoordinates: PropTypes.instanceOf(Array),
    onClickMarker: PropTypes.instanceOf(Object),
    showMarkerOnZoomLevel: PropTypes.number,
    defaultZoom: PropTypes.number,
    isFetchingTrees: PropTypes.bool,
    choosenTrees: PropTypes.instanceOf(Object)
};

ClusterCompanyMap.defaultProps = {
    mapRef: {},
    centerCoordinate: {},
    onMapReady: ()=>{},
    onChange: ()=> {},
    markerCoordinates: [],
    onClickMarker: ()=>{},
    showMarkerOnZoomLevel: 15,
    defaultZoom: 6,
    isFetchingTrees: true,
    choosenTrees: {},
}